const appConfig = () => {
  const env = process.env.REACT_APP_ENV ?? process.env.NODE_ENV ?? "development";
  if (env === 'development') {
    return {
      apiHost: process.env.REACT_API_HOST ?? 'https://uat.vamoos.com', ///uat server
      //apiHost: process.env.REACT_API_HOST ?? 'https://live.vamoos.com/v3',
      // apiHost: 'http://localhost:3000',
      composerHost: 'https://live.vamoos.com/composer',
      openWeatherMapHost: 'https://api.openweathermap.org/data/2.5',
      openWeatherMapApiKey: 'e428a512fcad9025d0c1ddf24740ad0f',
      generateWebstaysUrl: (operatorCode, brandingCode) => `https://${brandingCode ? brandingCode + '.' : ''}stays.vamoos.com/${operatorCode}`,
      analyticsMeasurementId: 'G-W0QXT71RVM' // unused
    }
  }
  if (env === 'dev-local') {
    return {
      apiHost: 'http://localhost:3000',
      composerHost: 'https://live.vamoos.com/composer',
      openWeatherMapHost: 'https://api.openweathermap.org/data/2.5',
      openWeatherMapApiKey: 'e428a512fcad9025d0c1ddf24740ad0f',
      generateWebstaysUrl: (operatorCode, brandingCode) => `https://${brandingCode ? brandingCode + '.' : ''}stays.vamoos.com/${operatorCode}`,
      analyticsMeasurementId: 'G-W0QXT71RVM' // unused
    }
  }

  if (env === 'uat') {
    return {
      apiHost: 'https://uat.vamoos.com',
      composerHost: 'https://uat.vamoos.com/composer',
      openWeatherMapHost: 'https://api.openweathermap.org/data/2.5',
      openWeatherMapApiKey: 'e428a512fcad9025d0c1ddf24740ad0f',
      generateWebstaysUrl: (operatorCode, brandingCode) => `https://${brandingCode ? brandingCode + '.' : ''}stays-uat.vamoos.com/${operatorCode}`,
      analyticsMeasurementId: 'G-W0QXT71RVM' // unused
    }
  }

  if (env === 'production' || env === 'beta') {
    return {
      apiHost: 'https://live.vamoos.com/v3',
      composerHost: 'https://live.vamoos.com/composer',
      openWeatherMapHost: 'https://api.openweathermap.org/data/2.5',
      openWeatherMapApiKey: 'e428a512fcad9025d0c1ddf24740ad0f',
      generateWebstaysUrl: (operatorCode, brandingCode) => `https://${brandingCode ? brandingCode + '.' : ''}stays.vamoos.com/${operatorCode}`,
      analyticsMeasurementId: 'G-W0QXT71RVM' // unused
    }
  }
};

export default appConfig();

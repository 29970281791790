import { useContext, useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import Context from '../../context';
import Container from '../global/Container';
import LoadingScreen from "../global/LoadingScreen";
import WeatherItem from "./WeatherItem";
import './index.scss';
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

const Weather = props => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [units, setUnits] = useState(null);
  const [activeIdx, setActiveIdx] = useState(null);
  const [locations, setLocations] = useState(null);
  const [onlyOneLocation, setOnlyOneLocation] = useState(true);

  const { itin, viewMode, setScreenNameOverride, t } = useContext(Context);
  const navigate = useNavigate();
  const { idx } = useParams();
  const weatherItemsContainer = useRef();
  const screenNameOverride = t('forecast', { lng: itin.language });

  useEffect(() => {
    if (!itin) return;
    setUnits(itin.temperatureScale);
  }, [itin]);

  useEffect(() => {
    const locs = props.type === 'daily' ? [props.location] : itin.locations;
    const onlyOneLoc = locs.length === 1;
    setLocations(locs);
    if (!onlyOneLoc) setOnlyOneLocation(false);

    const collapsed = idx === undefined;

    // If just one location, make it active by default
    setActiveIdx(
      onlyOneLoc ? 0
        : collapsed ? null
          : parseInt(idx)
    );

    setScreenNameOverride(onlyOneLoc || !collapsed ? screenNameOverride : null);

    setIsLoaded(true);
    return () => setScreenNameOverride(null);
  }, [screenNameOverride, idx, props.type, setScreenNameOverride, itin.locations, props.location]);

  const onLocationSelect = (event, idx) => {
    if (onlyOneLocation) return;

    const collapsed = idx === activeIdx;
    setActiveIdx(collapsed ? null : idx);
    setScreenNameOverride(collapsed ? null : screenNameOverride);
    navigateToIdx(idx)

    //scroll to clicked element
    weatherItemsContainer.current.scrollTo({
      top: (event.target.offsetHeight * idx),
      behavior: 'smooth'
    });
  };

  const navigateToIdx = useCallback((idx) => {
    const newPath = `/${itin.localData.urlKey}/weather${idx !== null ? `/${idx}` : ''}`;
    navigate(newPath);
  }, [itin.localData?.urlKey, navigate])

  //if the location idx in the URL exceeds the maximum allowable then redirected to the last possible idx
  useEffect(() => {
    if (!idx || props.type === 'daily' || locations === null || idx <= locations?.length - 1) return;
    navigateToIdx(locations?.length - 1);
  }, [idx, locations, navigateToIdx, props.type])

  const renderContent = () => {
    const weatherItems = locations.map((l, i) => {
      return (
        <WeatherItem
          units={units}
          key={l.id}
          data={l}
          active={i === activeIdx}
          hiddenIfNotActive={activeIdx !== null}
          onClick={(e) => onLocationSelect(e, i)}
          onKeyDown={(e) => e.key === 'Enter'? onLocationSelect(e, i) : null}
          onlyOneLocation={onlyOneLocation}
        />
      )
    });

    return (
      <>
        <ul className="weather-items-container"
            ref={weatherItemsContainer}>
          {weatherItems}
        </ul>
        <div className="toggle-units-container">
          <ToggleButtonGroup
            value={units}
            onChange={(e, value) => setUnits(units => value || units)}
            exclusive={true}
          >
            <ToggleButton value="C">&deg;C</ToggleButton>
            <ToggleButton value="F">&deg;F</ToggleButton>
          </ToggleButtonGroup>
        </div>
      </>
    );
  };

  if (!isLoaded || !units) return <LoadingScreen />

  if (props.type === 'daily') {
    return (
      <div className={`weather daily-weather daily-weather-${viewMode}`}>
        {renderContent()}
      </div>
    );
  }

  return (
    <Container
      className="container-weather"
      mainElementClassName={`weather weather-${viewMode}`}
    >
      {renderContent()}
    </Container>
  );
};

export default Weather;
